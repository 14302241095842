import Vue from 'vue'
import App from '@/View/App.vue'
import router from './router'
import '@/utils/lib-flexible/flexible'
import '@/assets/css/index.css'
// 动画 animate.css
require('@/utils/animate.css/animate.min.css')
// 滚动动画 wow.js
import { WOW } from '@/utils/wow2.js'
import ElementUI from 'element-ui'
import 'normalize.css'
import Axios from 'axios'




Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$axios = Axios
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default  需要执行动画的元素的 class
  animateClass: 'animated', // default  animation.css 动画的 class
  offset: 150, // default  距离可视区域多少开始执行动画
  mobile: true, // default  是否在移动设备上执行动画
  live: false,  // 异步加载的内容是否有效

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('#app')
